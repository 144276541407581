import { AxiosResponse } from "axios";
import HttpClient from "./HttpClient";
import { ENDPOINTS } from "./endpoints";
import { HasTalentLibraryCollectionRes } from "types/responses";

export const enableReverseTrial = async (): Promise<AxiosResponse> => {
  return await HttpClient.put(`${ENDPOINTS.reverseTrial.enable}`);
};

export const hasTalentLibraryCollection = async (): Promise<HasTalentLibraryCollectionRes> => {
  const res = await HttpClient.get(`${ENDPOINTS.reverseTrial.hasTalentLibraryCollection}`);

  return res.data;
};
