import React, { FC } from "react";
import { useResponsive, useToggle } from "ahooks";
import { TopBarMenuStyles } from "./TopBarMenu.styles";

// Components
import { ProfileMenu } from "./components/ProfileMenu";
import { MessagesMenu } from "./components/MessagesMenu";
import { BranchesMenu } from "./components/BranchesMenu";
import { DiscussionsMenu } from "./components/DiscussionsMenu";
import { ImpersonationBackButton } from "./components/ImpersonationBackButton";
import { StartReverseTrialButton } from "./components/StartReverseTrialButton";
import { StartReverseTrialModal } from "@components/ReverseTrial/StartReverseTrialModal";

// Stores
import { useConfigurationStore } from "@stores";

// Permissions
import permissions from "@utils/permissions";

const TopBarMenu: FC = () => {
  // Responsive breakpoint. sm means until 576px.
  const { md } = useResponsive();
  const { userProfileData } = useConfigurationStore();

  const [isTrialModalOpen, { toggle: toggleTrialModal }] = useToggle(false);

  // Permissions. Some checks are initiated at component level (ex: branches menu, Profile sub-menu items).
  const showImpersonationBackButton = userProfileData?.impersonated;
  const canEnableReverseTrial = userProfileData?.policies.can_enable_reverse_trial;
  const { canAccessDiscussions } = permissions.discussionsPermissions;
  const { canAccessMessages } = permissions.messagesPermissions;
  const showDiscussions = canAccessDiscussions() && md;
  const showMessages = canAccessMessages() && md;
  const showBranches = md;

  return (
    <section data-testid="topbar-menu" css={TopBarMenuStyles}>
      {canEnableReverseTrial && <StartReverseTrialButton onClick={toggleTrialModal} />}
      {showMessages && <MessagesMenu />}
      {showDiscussions && <DiscussionsMenu />}
      {showBranches && <BranchesMenu />}
      {md && showImpersonationBackButton && <ImpersonationBackButton />}
      <ProfileMenu
        isImpersonated={showImpersonationBackButton}
        toggleTrialModal={toggleTrialModal}
      />
      <StartReverseTrialModal isOpen={isTrialModalOpen} toggleModal={toggleTrialModal} />
    </section>
  );
};

export default TopBarMenu;
