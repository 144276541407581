import { css } from "@emotion/react";

export const reverseTrialModalCustomStyles = css`
  z-index: 10000;
  background-color: rgba(0, 0, 0, 0.9);
`;

export const trialContainerStyle = css`
  max-height: 70vh;
  @media only screen and (max-height: 600px) {
    max-height: 50vh;
  }
  overflow: auto;
`;

export const modalFooter = css`
  display: flex;
  gap: 1rem;
`;

export const sendRight = css`
  margin-left: auto;
`;
