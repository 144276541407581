import { css } from "@emotion/react";

export const watchDemoButtonStyles = ({ colors }) => css`
  padding: 0.5rem !important;
  padding-inline-end: 1rem !important;
  display: flex;

  .button-text {
    color: ${colors.black};
    white-space: nowrap;
  }
`;
