import { css } from "@emotion/react";
import { mq } from "@utils/breakpoints";

export const startReverseTrialButtonStyles = ({ colors }) => css`
  padding: 0.5rem !important;
  padding-inline-end: 1rem !important;
  display: none;

  ${mq["lg"]} {
    display: flex;
  }

  .button-text {
    color: ${colors.black};
    white-space: nowrap;
  }
`;
