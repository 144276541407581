import queryKeys from "@constants/queryKeys";
import { Button, Modal, Text } from "@epignosis_llc/gnosis";
import { useApplyTranslations } from "@hooks";
import { generalNotification } from "@utils/helpers";
import React, { FC } from "react";
import { useMutation, useQueryClient } from "react-query";
import {
  trialContainerStyle,
  modalFooter,
  sendRight,
  reverseTrialModalCustomStyles,
} from "./styles";
import { enableReverseTrial } from "@api/reverseTrial";
import { useConfigurationStore } from "@stores";
import { WatchDemoButton } from "@layouts/DashboardLayout/components/TopBarMenu/components/WatchDemoButton";

type ReverseTrialModalProps = {
  isOpen: boolean;
  toggleModal: () => void;
};

export const StartReverseTrialModal: FC<ReverseTrialModalProps> = ({ isOpen, toggleModal }) => {
  const { t } = useApplyTranslations();

  const queryClient = useQueryClient();

  const { userProfileData } = useConfigurationStore();
  const canWatchDemo = userProfileData?.policies.can_watch_demo ?? false;

  const handleStartTrial = async (): Promise<void> => {
    try {
      await enableReverseTrial();
      queryClient.invalidateQueries([queryKeys.domainSettings]);
      queryClient.invalidateQueries([queryKeys.userProfile]);
    } catch {
      throw new Error("Failed to start trial");
    }
  };

  const { mutate: handleStartTrialMutation, isLoading: enableReverseTrialLoading } = useMutation(
    () => handleStartTrial(),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([queryKeys.reverseTrial.enable]);
        generalNotification("success", <p>{t("reverseTrial.activatedSuccessfully")}</p>);
        toggleModal();
      },
      onError: () => {
        toggleModal();
        generalNotification("error", <p>{t("reverseTrial.activationFailed")}</p>);
      },
    },
  );

  return (
    <Modal
      data-testid="reverse-trial-modal"
      size="lg"
      style={reverseTrialModalCustomStyles}
      isOpen={isOpen}
      onClose={toggleModal}
      closeOnOutsideClick={true}
    >
      <Modal.Header>
        <Text fontSize="xl" weight="700">
          {t("reverseTrial.modalHeader")}
        </Text>
      </Modal.Header>
      <Modal.Body>
        <div css={trialContainerStyle}>
          <Text fontSize="md">
            Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
            Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum Lorem Ipsum
          </Text>
        </div>
      </Modal.Body>
      <Modal.Footer>
        <div css={modalFooter}>
          <Button
            data-testid="reverse-trial-modal-yes-button"
            variant="solid"
            onClick={(): void => handleStartTrialMutation()}
            isLoading={enableReverseTrialLoading}
          >
            {t("general.yes")}
          </Button>
          <Button
            data-testid="reverse-trial-modal-cancel-button"
            color="secondary"
            className="cancel-btn"
            onClick={toggleModal}
          >
            {t("general.cancel")}
          </Button>
          {canWatchDemo && (
            <div css={sendRight}>
              <WatchDemoButton />
            </div>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};
