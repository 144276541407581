import { Button, Text } from "@epignosis_llc/gnosis";
import React, { FC } from "react";
import { startReverseTrialButtonStyles } from "./StartReverseTrialButton.styles";
import { CirclePlaySVG } from "@epignosis_llc/gnosis/icons";
import { useApplyTranslations } from "@hooks";

const StartReverseTrialButton: FC<{ onClick: () => void }> = ({ onClick }) => {
  const { t } = useApplyTranslations();

  return (
    <Button
      css={startReverseTrialButtonStyles}
      variant="solid"
      color="primaryLight"
      className="start-trial-button"
      iconBefore={CirclePlaySVG}
      onClick={onClick}
      aria-role={t("reverseTrial.startTrial")}
    >
      <Text as="p" fontSize="sm" className="button-text">
        {t("reverseTrial.startTrial")}
      </Text>
    </Button>
  );
};

export default StartReverseTrialButton;
